// YourTransactions.js

import React, { useState, useEffect } from 'react';
import Header from './Header';
import { useGoogleAuth } from './GoogleAuthContext';
import './YourTransactions.css';

import { COMPLETED } from './utils/statuses/request_a_ticket_statuses';

const BACKEND_IP = process.env.REACT_APP_BACKEND_IP;

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

function YourTransactions() {
  const { userEmail } = useGoogleAuth();
  const [buyerListings, setBuyerListings] = useState([]);
  const [sellerListings, setSellerListings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [activeTab, setActiveTab] = useState('Buying');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    fetch(`${BACKEND_IP}/listings/get-all-listings`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userEmail }),
    })
      .then((response) => response.json())
      .then((data) => {
        const buyer = data.filter((listing) => listing.buyerInformation?.buyerEmail === userEmail);
        const seller = data.filter((listing) => listing.sellerInformation?.sellerEmail === userEmail);
        setBuyerListings(buyer);
        setSellerListings(seller);
      })
      .catch((error) => console.error('Error fetching listings:', error));
  }, [userEmail]);

  const handleListingClick = (listing) => {
    setSelectedListing(listing);
    setIsModalOpen(true);
    setCopied(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedListing(null);
  };

  const activeListings = activeTab === 'Buying' ? buyerListings : sellerListings;

  const parseDate = (dateString) => {
    return new Date(dateString);
  };

  const sortedActiveListings = [...activeListings].sort((a, b) => {
    const dateA = parseDate(a.eventInformation.datetime.local.date);
    const dateB = parseDate(b.eventInformation.datetime.local.date);
    return dateB - dateA;
  });

  const inProgressListings = sortedActiveListings.filter(
    (listing) => listing.transactionInformation.status !== COMPLETED
  );

  const completedListings = sortedActiveListings.filter(
    (listing) => listing.transactionInformation.status === COMPLETED
  );

  const shareLink = (contractAddress) => {
    return `${window.location.origin}/acceptrequest?contractAddress=${contractAddress}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(shareLink(selectedListing.transactionInformation.contractAddress));
    setCopied(true);
  };

  return (
    <div className="YourTransactions">
      <Header />

      <h1 className="your-transactions-title">Your Transactions</h1>

      <div className="tabs">
        <div
          className={`tab ${activeTab === 'Buying' ? 'active' : ''}`}
          onClick={() => setActiveTab('Buying')}
        >
          Buying
        </div>
        <div
          className={`tab ${activeTab === 'Selling' ? 'active' : ''}`}
          onClick={() => setActiveTab('Selling')}
        >
          Selling
        </div>
      </div>

      <div className="listings-container">
        <div className="section">
          <h2 className="section-title">In Progress</h2>
          {inProgressListings.length > 0 ? (
            <div className="card-grid">
              {inProgressListings.map((listing) => (
                <div
                  key={listing._id}
                  className="listing-card"
                  onClick={() => handleListingClick(listing)}
                >
                  <img
                    src={listing.eventInformation.image || 'https://via.placeholder.com/300x200'}
                    alt={listing.eventInformation.name || 'Event Image'}
                    className="listing-image"
                  />
                  <h3 className="listing-event-name">
                    {listing.eventInformation.name || 'Event Name'}
                  </h3>
                  <p className="listing-event-date">
                    {listing.eventInformation.datetime.local.date || 'Date: N/A'}
                  </p>
                  <div className="listing-details">
                    <span className="listing-price">
                      ${listing.transactionInformation.price || '0.00'}
                    </span>
                    <span
                      className={`listing-status ${
                        listing.transactionInformation.status === COMPLETED ? 'completed' : ''
                      }`}
                    >
                      {listing.transactionInformation.status || 'N/A'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="no-listings">No in-progress listings.</p>
          )}
        </div>

        <div className="section">
          <h2 className="section-title">Completed</h2>
          {completedListings.length > 0 ? (
            <div className="card-grid">
              {completedListings.map((listing) => (
                <div key={listing._id} className="listing-card">
                  <img
                    src={listing.eventInformation.image || 'https://via.placeholder.com/300x200'}
                    alt={listing.eventInformation.name || 'Event Image'}
                    className="listing-image"
                  />
                  <h3 className="listing-event-name">
                    {listing.eventInformation.name || 'Event Name'}
                  </h3>
                  <p className="listing-event-date">
                    {listing.eventInformation.datetime.local.date || 'Date: N/A'}
                  </p>
                  <div className="listing-details">
                    <span className="listing-price">
                      ${listing.transactionInformation.price || '0.00'}
                    </span>
                    <span className="listing-status completed">
                      {listing.transactionInformation.status || 'N/A'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="no-listings">No completed listings.</p>
          )}
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {selectedListing && (
          <div className="modal-body">
            <h2 className="modal-title">{selectedListing.eventInformation.name}</h2>
            <p className="modal-event-date">
              {selectedListing.eventInformation.datetime.local.date || 'Date: N/A'}
            </p>
            <p className="modal-text">Share this link:</p>
            <a
              href={shareLink(selectedListing.transactionInformation.contractAddress)}
              className="modal-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {shareLink(selectedListing.transactionInformation.contractAddress)}
            </a>
            <button className="modal-copy-button" onClick={handleCopy}>
              {copied ? 'Copied' : 'Copy Link'}
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default YourTransactions;
